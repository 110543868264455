/* Command provided by Tailwind CLI during build step via PostCSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Note, 99% of styles should go in an individual component using Styled Components
   This is only for global styles, of which there should be few and far between. */

.splash-background {
    background-image: linear-gradient(
            16deg,
            rgba(116, 116, 116, 0.02) 0%,
            rgba(116, 116, 116, 0.02) 25%,
            transparent 25%,
            transparent 96%,
            rgba(177, 177, 177, 0.02) 96%,
            rgba(177, 177, 177, 0.02) 100%
        ),
        linear-gradient(
            236deg,
            rgba(148, 148, 148, 0.02) 0%,
            rgba(148, 148, 148, 0.02) 53%,
            transparent 53%,
            transparent 59%,
            rgba(56, 56, 56, 0.02) 59%,
            rgba(56, 56, 56, 0.02) 100%
        ),
        linear-gradient(
            284deg,
            rgba(16, 16, 16, 0.02) 0%,
            rgba(16, 16, 16, 0.02) 46%,
            transparent 46%,
            transparent 71%,
            rgba(181, 181, 181, 0.02) 71%,
            rgba(181, 181, 181, 0.02) 100%
        ),
        linear-gradient(
            316deg,
            rgba(197, 197, 197, 0.02) 0%,
            rgba(197, 197, 197, 0.02) 26%,
            transparent 26%,
            transparent 49%,
            rgba(58, 58, 58, 0.02) 49%,
            rgba(58, 58, 58, 0.02) 100%
        ),
        linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type='range']::-webkit-slider-thumb {
        width: 17px;
        -webkit-appearance: none;
        appearance: none;
        height: 17px;
        cursor: pointer;
        background: #fff;
        box-shadow: -405px 0 0 400px #1a1d67;
        border-radius: 50%;
    }

    input[type='range']:focus {
        outline: none;
    }
}

button:focus {
    outline: none;
}

.tab-content-container {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

input:disabled,
textarea:disabled {
    background-color: transparent;
}
