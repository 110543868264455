.App {
    font-family: sans-serif;
    text-align: center;
}

/* just the line */
.sparkline {
    stroke: #06abdc;
    fill: none;
}

/* style fill area and line colors using specific class name */
.sparkline--fill {
    fill: #06abdc;
    opacity: 40%;
}

.sparkline--line {
    stroke: #06abdc;
}

*[hidden] {
    display: none;
}

.tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 9999;
}

.sparkline--cursor {
    stroke: orange;
}

.sparkline--spot {
    fill: red;
    stroke: red;
}
